<template>
    <div>
      <div class="wrap">
        <div class="top-area">
          <div class="title">
            Create
          </div>
          <div class="step">
            <div class="txt">NFT 정보</div><div class="right-arrow"></div>
            <div class="txt active">와인 정보</div><div class="right-arrow"></div>
            <div class="txt">와인 설명</div>
          </div>
        </div>
        <div class="flex-row">
          <div class="form flex-align-start">
            <div class="image-area-t2">
              <div class="field-image-file">
                <div class="ic-plus"></div>
                <div class="title">
                  등록하실 와인 NFT의 이미지를 등록해주세요.
                </div>
                <div class="subject">
                  jpg, png의 파일만 업로드 가능합니다<br/>
                  권장사이즈 : 600*600
                </div>
              </div>
            </div>
            <div class="info-area">
              <div class="brand-name">
                Baron Philippe de Rothschild
              </div>
              <div class="nft-name">
                [NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2019
              </div>
              <div class="stock-tooltip">
                <div class="line">
                  <div class="title">생성 수량</div>
                  <div class="content">3</div>
                </div>
                <div class="line">
                  <div class="title">판매 가격</div>
                  <div class="content">
                    550USDC
                    <span>(3,000,000원)</span>
                  </div>
                </div>
              </div>
              <dl>
                <dt>
                  국가
                </dt>
                <dd>
                  <input type="text" placeholder="국가명을 입력해주세요">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="text" placeholder="지역명을 입력해주세요">
                </dd>
              </dl>
              <dl>
                <dt>프로듀서</dt>
                <dd>
                  <input type="text" placeholder="프로듀서명을 입력해주세요" />
                </dd>
              </dl>
              <dl>
                <dt>Source</dt>
                <dd>
                  <input type="text" placeholder="Source를 입력해주세요"/>
                </dd>
              </dl>
              <dl>
                <dt>아펠라씨옹</dt>
                <dd>
                  <input type="text" placeholder="아펠라씨옹을 입력해주세요" />
                </dd>
              </dl>
              <dl>
                <dt>종류</dt>
                <dd>
                  <input type="text" placeholder="와인의 종류를 입력해주세요" />
                </dd>
              </dl>
              <dl>
                <dt>등급</dt>
                <dd>
                  <input type="text" placeholder="와인의 등급을 입력해주세요" />
                </dd>
              </dl>
              <dl>
                <dt>빈티지</dt>
                <dd>
                  <input type="text" placeholder="와인의 생산년도를 입력해주세요" />
                </dd>
              </dl>
              <dl>
                <dt>포맷</dt>
                <dd>
                  <input type="text" placeholder="포맷을 입력해주세요" />
                </dd>
              </dl>
              <dl>
                <dt>생산자</dt>
                <dd>
                  <input type="text" placeholder="생산자명을 입력해주세요" />
                </dd>
              </dl>
              <dl>
                <dt>케이스</dt>
                <dd>
                  <label class="winex-radio"><input type="radio" name="radio-test" value="단품" v-model="radioWineCase"><span>단품</span></label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label class="winex-radio"><input type="radio" name="radio-test" value="박스" v-model="radioWineCase"><span>박스</span></label>
                </dd>
              </dl>

              <div class="btn-row">
                <router-link :to="{path: `/users/minter/create/step1`}" class="prev-step-btn">
                  이전
                </router-link>
                <router-link :to="{path: `/users/minter/create/step3`}" class="next-step-btn-2">
                  다음
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
    export default {
      name: 'CreateNft',
      components: {

      },
        created() {

        },
        data () {
            return  {
              radioWineCase:"",
            }
        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
@import "@/assets/scss/create-nft.scss";
</style>